































































import { Component, Vue } from 'vue-class-decorator'
import { AccessRightsModule } from '@/store'

@Component({})
export default class RenderMenuComponent extends Vue {
  @AccessRightsModule.Getter('getMenuList') menu!: Array<object>
}
